<template>
  <div class="work-center">
    <div class="work-head flex bg-white">
      <div class="head-item" @click="toPage('/workCenter/signUp')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xuefei"></use>
        </svg>
        <span>报名/续费</span>
      </div>
      <div class="head-item" @click="toPage('/workCenter/recharge')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-fuwufeijianmian"></use>
        </svg>
        <span>余额充值</span>
      </div>
      <div class="head-item" @click="toPage('/workCenter/backExpense')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-banfei"></use>
        </svg>
        <span>补费</span>
      </div>
      <div class="head-item" @click="toPage('/workCenter/transferCourses')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-zhuanzhang"></use>
        </svg>
        <span>转课</span>
      </div>
      <div class="head-item" @click="toPage('/workCenter/refund')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-feiyongtiaozhengdan"></use>
        </svg>
        <span>退费</span>
      </div>
      <!-- <div class="head-item" @click="toPage('/workCenter/teachBookFee')">
        <svg class="icon" aria-hidden="true"><use xlink:href="#icon-shubenfei"></use></svg>
        <span>教材杂费</span>
      </div> -->
      <!-- <div class="head-item" @click="toPage('/workCenter/signUp')">
        <svg class="icon" aria-hidden="true"><use xlink:href="#icon-kaoshifei"></use></svg>
        <span>积分</span>
      </div> -->
    </div>

    <div class="work-title bg-white padding">
      <!-- <div>
        <el-button type="primary" size="mini">我的今日</el-button>
        <el-button type="primary" size="mini">校区今日</el-button>
      </div> -->
      <div
        class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
      >
        <div class="flex aligin-items-center">
          <div @click="openDate">
            <span>经办日期</span>
            <i class="el-icon-caret-bottom" />
            <el-divider direction="vertical"></el-divider>
          </div>
          <!-- <el-popover width="150" trigger="click" @show="isShow = !isShow" @hide="isShow = !isShow">
            <el-radio-group v-model="isArrearage">
              <el-radio :label="3" class="padding-xs">是</el-radio>
              <el-radio :label="6" class="padding-xs">否</el-radio>
            </el-radio-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>是否欠费</span>
              <i :class="isShow == true ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover> -->
          <!-- <el-popover width="350" trigger="click">
            <el-input v-model="classInput" placeholder="" size="mini" />
            <el-checkbox-group v-model="classList">
              <el-checkbox class="padding-xs" label="蜀西路小四1v1(六星级课程)课程 A" />
              <el-checkbox class="padding-xs" label="蜀西路小四1v1(六星级课程)课程 B" />
              <el-checkbox class="padding-xs" label="蜀西路小四1v1(六星级课程)课程 C" />
            </el-checkbox-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>课程</span>
              <i class="el-icon-caret-bottom" />
              <i class="el-icon-link" />
              <span class="text-grey">教材杂费</span>
              <i class="el-icon-caret-bottom text-grey" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover> -->
          <!-- <el-popover width="150" trigger="click">
            <div class="bg-white flex flex-direction"><span class="padding-sm">销售员</span></div>
            <div slot="reference" class="aligin-items-center flex">
              <span>售前人员类型</span>
              <i class="el-icon-caret-bottom" />
              <i class="el-icon-link" />
              <span class="text-grey">售前人员</span>
              <i class="el-icon-caret-bottom text-grey" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover> -->
          <el-popover width="150" trigger="click">
            <el-checkbox-group v-model="queryList.order_type">
              <el-checkbox class="padding-xs" :label="1">报名</el-checkbox>
              <el-checkbox class="padding-xs" :label="4">账户余额变动</el-checkbox>
              <el-checkbox class="padding-xs" :label="5">补费</el-checkbox>
              <el-checkbox class="padding-xs" :label="6">转课</el-checkbox>
              <el-checkbox class="padding-xs" :label="7">停课</el-checkbox>
              <el-checkbox class="padding-xs" :label="8">复课</el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>订单类型</span>
              <i class="el-icon-caret-bottom" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover>
          <!-- <el-popover width="150" trigger="click">
            <el-checkbox-group v-model="orderTagList">
              <el-checkbox class="padding-xs" label="七月录掉" />
              <el-checkbox class="padding-xs" label="双十一活动" />
              <el-checkbox class="padding-xs" label="已退费" />
            </el-checkbox-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>订单标签</span>
              <i class="el-icon-caret-bottom" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover> -->
          <!-- <el-popover width="150" trigger="click">
            <el-input v-model="classInput" placeholder="" size="mini"></el-input>
            <el-checkbox-group v-model="operatorList">
              <el-checkbox class="padding-xs" label="王某某" />
              <el-checkbox class="padding-xs" label="周某某" />
              <el-checkbox class="padding-xs" label="张某某" />
            </el-checkbox-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>经办人</span>
              <i class="el-icon-caret-bottom" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover> -->
          <el-popover width="200" trigger="click">
            <!-- <el-input v-model="queryList.open_school" placeholder="" size="mini" /> -->
            <el-checkbox-group v-model="queryList.school_id">
              <el-checkbox
                class="padding-xs"
                :label="item.id"
                v-for="(item, index) of schoolList"
                :key="index"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
            <div slot="reference">
              <span>经办校区</span>
              <i class="el-icon-caret-bottom" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-checkbox-group v-model="queryList.status">
              <el-checkbox class="padding-xs" :label="2">待支付</el-checkbox>
              <el-checkbox class="padding-xs" :label="3">已完成</el-checkbox>
              <el-checkbox class="padding-xs" :label="4">已失效</el-checkbox>
              <el-checkbox class="padding-xs" :label="5">已作废</el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>订单状态</span>
              <i class="el-icon-caret-bottom" />
              <el-divider direction="vertical"></el-divider>
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <!-- <el-input v-model="queryList.source" placeholder="" size="mini"></el-input> -->
            <el-checkbox-group v-model="queryList.source">
              <!-- <el-checkbox class="padding-xs" :label="0">未知</el-checkbox> -->
              <el-checkbox class="padding-xs" :label="1">销售系统</el-checkbox>
              <el-checkbox class="padding-xs" :label="2">线上办理</el-checkbox>
              <el-checkbox class="padding-xs" :label="3">线下办理</el-checkbox>
              <el-checkbox class="padding-xs" :label="4">导入</el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="aligin-items-center flex">
              <span>订单来源</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
        </div>

        <div class="work-title-search">
          <el-input
            placeholder="请输入姓名,支持拼音缩写"
            v-model="queryList.KeyWord"
            class="input-with-select"
            size="mini"
          >
          </el-input>
        </div>
      </div>

      <div
        class="work-title-status flex margin-top-sm aligin-items-center justify-content-between"
      >
        <div class="flex aligin-items-center">
          <div class="text-gray flex aligin-items-center">
            <div>经办日期：</div>
            <el-date-picker
              @change="dateChange"
              value-format="yyyy-MM-dd"
              ref="datePicker"
              v-model="date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
            />
          </div>
          <!-- <div class="text-gray flex aligin-items-center  margin-left-lg">
            <div>订单状态：</div>
            <div v-for="(btn, btnIndex) in orderSatus" :key="btnIndex">
              <div :class="{ 'margin-left': btnIndex > 0 }">
                <el-button size="mini">
                  {{ btn }}
                  <i class="el-icon-close" @click="delOderStatus(btnIndex)" />
                </el-button>
              </div>
            </div>
          </div> -->
        </div>

        <div class="text-blue cursor" @click="clearSel">清空筛选</div>
      </div>
    </div>

    <!-- 列表 -->
    <as-list :ajaxMethods="{ list: 'getOrderList' }" :listQueryProp="queryList">
      <el-table-column width="70px" prop="id" label="订单号"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        width="80px"
        prop="student_name"
        label="学员姓名"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="phone"
        label="联系方式"
      ></el-table-column>
      <el-table-column
        prop="order_type_name"
        label="订单类型"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="order_title"
        label="交易内容"
        width="150"
      ></el-table-column>
      <el-table-column prop="pay_money" label="应收/应退"></el-table-column>
      <el-table-column prop="order_price" label="实收/实退"></el-table-column>
      <el-table-column prop="arrears_money" label="欠费"></el-table-column>
      <!-- <el-table-column prop="" label="积分"></el-table-column> -->
      <el-table-column
        prop="sale_customer_name"
        label="销售员"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="order_tags"
        label="订单标签"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        width="120"
        prop="handle_school_name"
        label="经办校区"
      ></el-table-column>
      <el-table-column width="95" prop="handle_date" label="经办日期">
        <template slot-scope="scope">
          <span>{{
            scope.row.handle_date | dateTimeFilter("yyyy-MM-dd")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="handle_user_name" label="经办人"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag size="mini" effect="dark" :type="scope.row.status | statusClassFilter">{{ scope.row.status_name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-dropdown @command="(item) => handleCommand(item, scope.row)">
            <label>
              操作
              <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
            </label>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>确认到款</el-dropdown-item>
                <el-dropdown-item>打印</el-dropdown-item>
                <el-dropdown-item>日志</el-dropdown-item> -->
              <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-dropdown-item command="cancel" v-if="scope.row.status!==5">作废订单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </as-list>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
  },
  data() {
    return {
      isShow: false,
      showDate: true,
      date: [],
      stuInput: "", // 学生姓名查询
      stuSelect: "",
      isArrearage: 3,
      classInput: "",
      classList: ["蜀西路小四1v1(六星级课程)课程 A"],
      // 筛选
      orderTypeList: [], // 订单类型
      orderTagList: [], // 订单标签
      operatorList: [], // 经办人
      schoolInput: "",
      schoolList: [], // 经办校区
      orderSatus: ["待支付", "已完成"], // 订单状态
      orderFrom: [], // 订单来源
      myList: [], // 自定义显示列
      queryList: {
        KeyWord: "",
        source: [],
        status: [],
        title: "",
        order_time_start: "",
        order_time_end: "",
        school_id: [],
        handle_school: [],
        order_type: [],
      },
    };
  },
  filters:{
    statusClassFilter(val){
      let classConfig=['','','warning','success','info','info','success','info'];
      return classConfig[val] ||'';
    }
  },
  created() {
    this.getSchoolList();
  },
  methods: {
    handleCommand(command, item) {
      if (command === "cancel") {
        this.updateOrderStatus(item.id, 5);
        return;
      }
      if(command === 'edit'){
        this.$router.push({
          path:'/workCenter/signUp',
          query:{
            id:item.id,
            redirect:this.$route.path
          }
        });
        return;
      }
    },
    updateOrderStatus(id, status) {
      this.API.postOrderChangeStatus({
        ids: id,
        status,
      }).then((res) => {
        if(res.success){
          this.$message.success('操作成功');
        }else{
          this.$message.error(res.message);
        }
        this.queryList = JSON.parse(JSON.stringify(this.queryList));
        
      });
    },
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 15,
      };
      this.API.OrganizationList(obj).then((res) => {
        if (res.success) {
          this.schoolList = res.data.rows;
        }
      });
    },
    toPage(src) {
      this.$router.push({
        path: src,
      });
    },
    openDate() {
      this.$refs.datePicker.pickerVisible = true;
    },
    dateChange(e) {
      this.queryList.order_time_start = e[0];
      this.queryList.order_time_end = e[1];
    },
    delOderStatus(index) {
      this.orderSatus.splice(index, 1);
    },
    // 编辑报名订单
    editSignOrder() {
      console.log("编码报名订单");
    },
    // 清空筛选
    clearSel() {
      this.date = [];
      this.queryList = {
        KeyWord: "",
        source: [],
        status: [],
        title: "",
        order_time_start: "",
        order_time_end: "",
        school_id: [],
        handle_school: [],
        order_type: [],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.work-center {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.work-title {
  width: 100%;
  .work-title-all {
    .work-title-content {
      margin-top: 10px;
      width: 80%;
      .el-icon-link {
        padding: 0 3px;
      }
      .el-icon-link {
        font-weight: bold;
      }
      .text-grey {
        color: #cccccc;
      }
    }
    .work-title-search {
      width: 25%;
    }
  }
}
</style>
<style scoped>
.icon {
  width: 50px;
  height: 50px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.el-input__inner {
  width: 220px !important;
}
>>> .el-select .el-input {
  width: 110px !important;
}
.el-button--mini {
  border-radius: 4px !important;
  padding: 6px 15px !important;
}
.head-item {
  cursor: pointer;
}

.bg-white {
  background-color: #ffffff;
}
.margin-top {
  margin-top: 20px;
}
.padding {
  padding: 20px 30px;
}
.work-head {
  padding: 10px 0;
}
.head-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
}
.head-item span {
  margin-top: 4px;
}
/* .work-title-content div{
  padding: 0 5px;
} */

.work-title-all {
  width: 100%;
}
.work-title-content-item {
  padding: 0 5px;
}
.border-right {
  border-right: 1px solid #cccccc;
}
.work-title-status {
  padding: 0 5px;
}
.work-list .border-tb {
  background-color: #f5f7fa;
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
</style>
